

export const sellerMenuItems = [
  {
    label: "Dashboard",
    path: "/seller/sellerdashboard",
    key: "dashboard",
  },
  {
    label: "Orders",
    path: "",
    key: "orders",
    children: [
      {
        key: "orders",
        title: "MOB orders",
        path: "/seller/SellerOrders",
      },
      {
        key: "storeorders",
        title: "Store orders",
        path: "/seller/storeorders",
      },
      {
        key: "Outofstock",
        title: "Out of stock",
        path: "/seller/outofstock",
      },
    ]
  },
 
  {
      label: "Manage products",
      path: "",
      key: "product",
      children: [
        {
          key: "inventoryAndPrice",
          title: "Inventory & price",
          path: "/seller/inventory-and-price",
        },
        {
          key: "managesellerproducts",
          title: "Catalogue",
          path: `/seller/catalogue`,
        },
        {
          key: "MOBlibrary",
          title: "MOB library",
          path: "/seller/mob-library",
        },
        
      ],
    },
    {
      label: " Payables/ recievables",
      path: "/seller/payables",
      key: "Payablesrecievables",
    },
   
  

  {
    label: "Reports",
    path: "",
    key: "reports",
    children: [
      {
        key: "mobCREDITsubscription",
        title: "mobCREDIT subscription",
        path: "/Seller/SubscriptionReports",
      },
      {
        key: "SalesSummary",
        title: "Store Sales",
        path: "/seller/SalesSummary",
      },

        {
          key: "mobCREDIT Sales",
          title: "mobCREDIT Sales",
          path: "/seller/mobCreditSales",
        },
        {
          key: "LineOfCredit",
          title: "Line of credit",
          path: "/Seller/lineOfCredits",
        },
       
    
      
    ],
  },
  {
    label: "MOB settlements",
    path: "/seller/Settlements",
    key: "MOBsettlements",
  },  
  {
    label: "Account and Settings",
    path: "/seller/Accountsandsettings",
    key: "accounts",
  },
];
export const sellerMenuItemsInActive = [
  {
    label: "Dashboard",
    path: "/seller/sellerdashboard",
    key: "dashboard",
  },
  {
    label: "Orders",
    path: "",
    key: "orders",
    children: [   
      {
        key: "orders",
        title: "MOB orders",
        path: "/seller/SellerOrders",
      },  
      
      {
        key: "storeorders",
        title: "Store orders",
        path: "/seller/storeorders",
      },
      {
        key: "Outofstock",
        title: "Out of stock",
        path: "/seller/outofstock",
      },
    ]
  },
  {
      label: "Manage products",
      path: "",
      key: "product",
      children: [
        {
          key: "inventoryAndPrice",
          title: "Inventory & price",
          path: "/seller/inventory-and-price",
        },
        {
          key: "managesellerproducts",
          title: "Catalogue",
          path: `/seller/catalogue`,
        },
        {
          key: "MOBlibrary",
          title: "MOB library",
          path: "/seller/mob-library",
        },
        
      ],
    },
 
    {
      label: " Payables/ recievables",
      path: "/seller/payables",
      key: "Payablesrecievables",
    },
   
   
  

  {
    label: "Reports",
    path: "",
    key: "reports",
    children: [
      {
        key: "SalesSummary",
        title: "Store Sales",
        path: "/seller/SalesSummary",
      },
      // {
      //   key: "mobCREDIT Sales",
      //   title: "mobCREDIT Sales",
      //   path: "/seller/mobCreditSales",
      // }
    ],
  },
  {
    label: "MOB settlements",
    path: "/seller/Settlements",
    key: "MOBsettlements",
  },  
  {
    label: "Account and Settings",
    path: "/seller/Accountsandsettings",
    key: "accounts",
  },
];

// export const sellerMenuItems = [
//   {
//     label: "Catalogue",
//     path: "/admin/sellers/catalogue",
//     key: "catalogue",
//   },
//   {
//     label: "Inventory & price",
//     path: "/admin/sellers/inventory-and-price",
//     key: "inventoryAndPrice",
//   },
//   {
//     label: "MOB library",
//     path: "/admin/sellers/mob-library",
//     key: "mobLibrary",
//   },
// ];

export const sellerRoutes = [
  "/admin/sellers/catalogue",
  "/admin/sellers/update-pricing",
  "/admin/sellers/inventory-and-price",
  "/admin/sellers/mob-library",
];
