import React, { useEffect, useState } from "react";
import "./SubscriptionReport.css";
import {
  //  Avatar, Dropdown, Menu,
  Col,  
  Typography,Row,Button,Modal
} from "antd";
import { saveAs } from "file-saver";
import MobCreditButtonIcon from "../../../../assets/seller/icons/Mobcreditlogo1.svg";
// eslint-disable-next-line 
import SearchInput from "../../../../components/Input/SearchInputs/SearchInput";
import {
 
  dateFormat,
  onlyDateFormat,

} from "../../../../commonFuntions/CommonUtilFunctions";
import CustomSelect from "../../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker";
import CustomTableWithPagination from "../../../../components/CustomTableWithPagination/CustomTableWithPagination";
import {  
  useLazyGetSubscriptionReportsQuery,
} from "../../../../apis/Seller/SellerReportsAPI.js";
import TableSkeleton from "../../../../components/skeleton/TableSkeleton";
// import { DashOutlined } from "@ant-design/icons";
// import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";
import MobCreditVideo from "./../../SellerMobCreditOnboarding/MobCreditVideo";
import dayjs from "dayjs";


const { Text } = Typography;
// eslint-disable-next-line 
const options = [
  {
    key: 1,
    value: "Export",
    label: "Export",
  },
];

const dateOptions = [
  {
    key: 1,
    value: "Today",
    label: "Today",
  },
 
  {
    key: 3,
    value: "last_7_day",
    label: "Last 7 day",
  },
  {
    key: 4,
    value: "last_30_day",
    label: "Last 30 day",
  },
  {
    key: 5,
    value: "last_60_day",
    label: "Last 60 day",
  },
  {
    key: 6,
    value: "Custom",
    label: "Custom",
  },
];

const SubscriptionReport = () => {

  const bmpCreditEnable = localStorage.getItem("bmp_credit_enable")==="true";
  const bmpPlanExpiry = localStorage.getItem("bmp_plan_expiry");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dateFilter, setDateFilter] = useState();
  // eslint-disable-next-line 
  const [searchedValue, setSearchedValue] = useState({});
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });
  // eslint-disable-next-line 
  const [currentPage, setCurrentPage] = useState(1);
  const [sorterState, setSorterState] = useState({
    order_id: "",
    sub_order_id: "",
    created_at: "",
  });
  const [getSubscriptionReportApi, { data: sellerSubscriptionReportData, isFetching }] =

  useLazyGetSubscriptionReportsQuery();
  // console.log("🚀 ~ SubscriptionReport ~ getSubscriptionReportApi:", sellerSubscriptionReportData)
  // const [
  //   getDownloadSellerSalesReportApi,
  //   { isFetching: DownloadReportFetching },
  // ] = useLazyGetSubscriptionReportsQuery();
  useEffect(() => {
    getSubscriptionReportApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;

      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }
   
    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value !== "Custom" && value !== null) {
      if (value === "clear_date") {
        getSubscriptionReportApi({
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.order_id && { sort_by: sorterState.order_id }),
          ...(sorterState.sub_order_id && {
            sort_by: sorterState.sub_order_id,
          }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: "",
          end_date: "",
        });
      } else {
        getSubscriptionReportApi({
          start_date: formattedDate,
          end_date: currentDate,
          ...(searchedValue?.search && { search: searchedValue?.search }),
          ...(sorterState.order_id && { sort_by: sorterState.order_id }),
          ...(sorterState.sub_order_id && {
            sort_by: sorterState.sub_order_id,
          }),
          ...(sorterState.created_at && { sort_by: sorterState.created_at }),
          page: 1,
        });
        setDateSearch({
          start_date: formattedDate,
          end_date: currentDate,
        });
      }
      setCurrentPage(1);
    }
    setDateFilter(value);
  };
  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      getSubscriptionReportApi({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
        ...(sorterState.order_id && { sort_by: sorterState.order_id }),
        ...(sorterState.sub_order_id && { sort_by: sorterState.sub_order_id }),
        ...(sorterState.created_at && { sort_by: sorterState.created_at }),
        page: 1,
      });
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });
    }
  };
  // eslint-disable-next-line 
  const sorterChange = (item) => {
    let key = item;
    setSorterState({
      // ...sorterState,
      [key.replace("-", "")]: item,
    });
    getSubscriptionReportApi({
      sort_by: item,
      ...(searchedValue?.search && { search: searchedValue?.search }),
      ...(dateSearch?.start_date && { start_date: dateSearch?.start_date }),
      ...(dateSearch?.end_date && { end_date: dateSearch?.end_date }),
      page: 1,
    });
  };
  const  DownloadInvoice = async (params) => {   
    if (params) {    
      saveAs(params);
    }   
  };
  
  // const handleExportApi = async () => {    
  //   let response = await getDownloadSellerSalesReportApi({
  //     ...(searchedValue.search && { search: searchedValue.search }),
  //     ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
  //     ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
  //     ...(sorterState.order_id && { sort_by: sorterState.order_id }),
  //     ...(sorterState.sub_order_id && { sort_by: sorterState.sub_order_id }),
  //     ...(sorterState.created_at && { sort_by: sorterState.created_at }),
  //     // download: true,
  //   });
  //   if (response?.status === "fulfilled") {
  //     // handlePdfDownload(response?.data?.data?.url);
  //     saveAs(response?.data?.data?.url);
  //   }
  // };

  const columns = [ 
    {
      title: "Date",    
        
      dataIndex: "created_at",
      key: "order_date",
      width: "250px",
      render: (params, record) => {
        return (
          <>
            {params && (              
                dateFormat(params)             
            )}
          </>
        );
      },
    },
   
    

    ////////////////////
    {
      title: "Invoice no",
      dataIndex: "invoice_no",
      key: "invoice_no",  
      width: "250px",   
      render: (params, record) => {
        return (
          <>
            {params && (              
                params             
            )}
          </>
        );
      },
    },

    {
      title: "Plan",
      dataIndex: "plan",
      align:"left",
      key: "Amount",  
      width: "250px", 
      render: (params, record) => {
        return (
         <Text>
            {params?.plan_name ? params?.plan_name : ""}
            </Text>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "plan",
      align:"right",
      key: "Amount",  
      width: "250px", 
      render: (params, record) => {
        return (
          <Text>
            ₹ {params?.price ? params?.price : ""}
          </Text>
        );
      },
    },    
    {
      title: "",
      dataIndex: "invoice",
      key: "invoice",   
      align:"center"  ,
      width: "250px",
      render: (params, record) => {
        return <Col><a href={params} target="_blank" rel="noreferrer">View invoice</a></Col>;
      },
    },

    
    {
      title: "",
      dataIndex: "invoice",
      key: "invoice",  
      align:"center"  , 
      width: "250px",
      render: (params, record) => {
        return <Col>
        <Button onClick={() => DownloadInvoice(params)}>Download</Button>
        </Col>;
      }, 
    },
  ];

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      // ...(sorterState.rfq_id && { sort_by: sorterState.rfq_id }),
      // ...(sorterState.created_at && { sort_by: sorterState.created_at }),
    };
    setCurrentPage(currPage);
    await getSubscriptionReportApi(params);
  };

  // const handleSearchedValue = (params) => {
  //   setSearchedValue(params);
  //   setCurrentPage(1);
  // };

  return (
    <>
    <Col className="SalesReport-wrapper">
      <Row>
        <Col span={12}><Text className="screen-heading">mobCREDIT subscription</Text></Col>
        <Col span={12} className="text-right"><Text>
        <Text
                        className="how-touse-link cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsModalVisible(true);
                        }}
                      >
                        How does it work?
                      </Text>
                     </Text></Col> 
      </Row>
      {bmpCreditEnable&&(
     <Row className="sub-strip d-flex" style={{justifyContent:"space-between"}}>
    
      <Col>
      <span className="active-text">Active
     <br/>
      <span className="active-text-sm"> Current plan: 1 month + 2 months FREE <br/>
      Next payment on {onlyDateFormat(bmpPlanExpiry)}</span>
      </span>
      </Col>
     
      <Col className="text-right">
      <img src={MobCreditButtonIcon} alt=""></img>
      </Col>
     </Row>
      )}
      <Col className="d-flex-colum" style={{ display: "flex", gap: "1rem"}}>
        {/* <SearchInput
          size={"large"}
          placeholder={"Search by customer name, mobile no, email"}
           width={"50%"}
          getLibraryApi={getSubscriptionReportApi}
          handleSearchedValue={handleSearchedValue}
          isLoading={isFetching}
        /> */}
        <Col className="action-sub-container">
          <CustomSelect
            placeholder={"Date"}
            width={"150px"}
            options={dateOptions}
            handleChange={handleChange}
            value={dateFilter}
          />
          {dateFilter === "Custom" && (
            <DateRangePicker
              onChange={handleChangeRangePicker}
              format={"DD-MMM-YYYY"}
            />
          )}

       {/* <CustomSelect
          placeholder={"Export"}
          width={"150px"}
          options={options}
          handleChange={handleExportApi}
          value={DownloadReportFetching ? <LoadingOutlined /> : "EXPORT"}
          disabled={DownloadReportFetching ? true : false}
          loading={DownloadReportFetching}
        /> */}
        </Col>
       
      </Col>
      <CustomTableWithPagination
        className="order-list-table table-fixed"
        //   tableExpandable={defaultExpandable}
        // tableDataSource={tableDataSource || []}
        tableDataSource={sellerSubscriptionReportData?.data}
        tableColumns={columns}
        locale={{
          emptyText: isFetching ? <TableSkeleton length={10} /> : "",
        }}
        isLoading={sellerSubscriptionReportData?.data?.length && isFetching}
        scroll={{ x: "100%" }}
        // currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={20}
        totalEntries={
          !isFetching && sellerSubscriptionReportData?.data?.length
        }
      />
    </Col>
    <Modal
    title="How does mobCREDIT work?"
    width={"65%"}
    height={"65%"}
    visible={isModalVisible}
    onCancel={() => setIsModalVisible(false)}
    footer={null}
  >
    <div className="padding-16px">
        <MobCreditVideo/>
        </div>
  </Modal>
  </>
  );
};

export default SubscriptionReport;
