// eslint-disable-next-line 
import { useCallback, useEffect, useState } from "react";
import {
  // eslint-disable-next-line 
  Avatar,
  Button,
  Col,
  // eslint-disable-next-line 
  Divider,
  Dropdown,
  // eslint-disable-next-line 
  Input,
  Menu,
  Popconfirm,
  // eslint-disable-next-line 
  Radio,
  // eslint-disable-next-line 
  Space,
  Typography,
  Tag,
} from "antd";
// eslint-disable-next-line 
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { ReactComponent as DeleteSubIcon } from "../../../../assets/icons/DeleteSubOrder.svg";
// eslint-disable-next-line 
import { ReactComponent as TableInfo1 } from "../../../../assets/icons/TableInfo2.svg";
// eslint-disable-next-line 
import { ReactComponent as TableInfo } from "../../../../assets/icons/TableInfo.svg";
import { ReactComponent as AddIcon } from "../../../../assets/icons/AddIcon.svg";
import { ReactComponent as DotIcon } from "../../../../assets/icons/DotIcon.svg";
// eslint-disable-next-line 
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search-3.svg";
// eslint-disable-next-line 
import { ReactComponent as TableShopIcon } from "../../../../assets/icons/TableShopicon.svg";
import { ReactComponent as Document } from "../../../../assets/icons/viewDocument.svg";
import { useDispatch } from "react-redux";
import {
  // useGetAllVendorsQuery,
  useLazyGetAllVendorsQuery,
} from "../../../../apis/createQuote";
// import DeliveryDatePopover from "./DeliveryDatePopover";
import "./tableHeader.css";
// eslint-disable-next-line 
import { baseUrl } from "../../../../commonUtils/commonUtils";
// eslint-disable-next-line 
import { debounce } from "lodash";
// eslint-disable-next-line 
import TableSkeleton from "../../../../components/skeleton/TableSkeleton";
import CreateOrderDrawer from "../../../../components/Drawer/CreateOrderDrawer/CreateOrderDrawer";
// eslint-disable-next-line 
import { colorMapping } from "../../../../commonFuntions/CommonUtilFunctions";

const { Text } = Typography;
const TableHeader = ({
  handleAddRow,
  tableIndex,
  handleDeleteTable,
  sellerList,
  getAllVendors,
  selectedSeller,
  setSelectedSeller,
  editCondition,
  initialValue,
  bmpPrice,
  getBmpPrice,
  subOrderID = false,
  getProductApi,
  getProductListData,
  getProductModalApi,
  QuoteCreationFlag,
}) => {
  console.log(selectedSeller, "🎅🏼🤶🏼👼🏼")
  const bmpId = localStorage.getItem('bmp_id');
const bmpName = localStorage.getItem('bmp_name');
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  // eslint-disable-next-line 
  const [getAllVendorsApi, { data: vendorList, isFetching: isLoading }] =
    useLazyGetAllVendorsQuery();
  const handleOpenRfqModal = () => {
    setDrawerVisible(true);
  };
  // const { data: vendorList } = useGetAllVendorsQuery();

  
  useEffect(() => {
    if (vendorList) {
      dispatch(getAllVendors(vendorList?.data?.results));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorList]);
  useEffect(() => {
    getAllVendorsApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (editCondition && sellerList?.length > 0 && vendorList?.data) {
      const list = initialValue?.map((item, index) => {
        const sellerData = sellerList
          ?.map((element, i) => {
            if (element.bmp_id === item) {
              const editObj = {
                ...element,
                label: element.vendor_name,
                vendor_name: element.vendor_name,
                key: element.bmp_id,
                bmp_id: element.bmp_id,
              };
              return editObj;
            }
            return null;
          })
          .filter(Boolean);
        return sellerData;
      });
      let finalList = [];
      list?.map((item) => {
        item.map((element) => {
          return finalList.push(element);
        });
        return null;
      });
      if (!(initialValue?.length <= selectedSeller?.length)) {
        setSelectedSeller(finalList || selectedSeller);
      }
    }
    else{
      let finalList = [];
      if (bmpId && bmpName) {
        finalList.push({
          bmp_id: bmpId,
          label: bmpName,
          vendor_name: bmpName,
          key: bmpId,
        });
      }
      if (!(initialValue?.length <= selectedSeller?.length)) {
        setSelectedSeller(finalList || selectedSeller);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCondition, sellerList, vendorList]);



  
  const subOrderMenu = (
    <Menu>
      <Popconfirm
        title={
          <Text
            style={{
              display: "block",
            }}
          >
            Are you sure ?
          </Text>
        }
        style={{ width: "150px" }}
        trigger="click"
        onConfirm={() => handleDeleteTable(tableIndex, bmpPrice[tableIndex])}
      >
        <Menu.Item
          key="1"
          // onClick={() => handleDeleteTable(tableIndex)}
        >
          {" "}
          <Col
            style={{
              color: "#bf2600",
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
            // onClick={handleOpenPopover}
          >
            <DeleteSubIcon />
            <Text
              style={{
                color: "#bf2600",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Delete suborder
            </Text>
          </Col>
        </Menu.Item>
      </Popconfirm>
    </Menu>
  );

  
  return (
    <>
      <Col
        key={tableIndex}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "16px",
          }}
        >
          <Tag
            style={{
              width: "160px",
              height: "33px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              fontWeight: 500,
              fontSize: "14px",
              cursor: "Pointer",
              borderRadius: "12px",
            }}
            className={"btn-viewcatalogue"}
            onClick={handleOpenRfqModal}
          >
            <Document />
            View catalogue 
          </Tag>
          <Button
            onClick={() => handleAddRow(tableIndex)}
            type="primary"
            style={{
              width: "140px",
              fontWeight: 500,
              height: "33px",
              borderRadius: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            icon={<AddIcon style={{ alignItems: "center" }} />}
          >
            Add item
          </Button>
          {tableIndex !== 0 && (
            <Dropdown overlay={subOrderMenu} trigger={["click"]}>
              <Col
                style={{
                  height: "33px",
                  width: "33px",
                  borderRadius: "8px",
                  border: "solid 1px #eaeaea",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <DotIcon />
              </Col>
            </Dropdown>
          )}
        </Col>
      </Col>
      <CreateOrderDrawer
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        data={selectedSeller[tableIndex]}
        handleAddRow={handleAddRow}
        tableIndex={tableIndex}
        getProductApi={getProductApi}
        getProductListData={getProductListData}
        getProductModalApi={getProductModalApi}
      />
    </>
  );
};

export default TableHeader;
